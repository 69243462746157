import { memo, Fragment } from 'react';
import { cn, List } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './ImageAreas.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface ImageData {
  src?: string;
  alt?: string;
}

export interface ImageAreasProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  images?: ImageData[];
  cnImage?: string;
  imageLazyLoading?: boolean;
}

const ImageAreas: FC<ImageAreasProps> = (props) => {
  const { className, images, cnImage, imageLazyLoading, ...restProps } = props;
  const widthItem = Number((100 / images.length).toFixed(5));

  return (
    <List
      {...restProps}
      className={cn(styles.images, className)}
      items={images}
      renderChild={(image: ImageData, index) => (
        <Fragment key={index}>
          <div
            className={styles.item}
            style={{ width: `${widthItem}%`, left: `${widthItem * index}%` }}
          />
          <Img
            cnImage={cnImage}
            className={styles.image}
            src={image.src}
            alt={image.alt}
            loading={index !== 0 || imageLazyLoading ? 'lazy' : 'eager'}
            itemProp='image'
          />
        </Fragment>
      )}
    />
  );
};

export default memo(ImageAreas);
