import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './SolutionsTag.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { ISolutionTag } from './typings';

export interface SolutionsTagProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  tag: ISolutionTag;
}

const SolutionsTag: FC<SolutionsTagProps> = (props) => {
  const { className, tag, ...restProps } = props;
  const { title, icon } = tag;

  return (
    <div
      {...restProps}
      className={cn(styles.container, className, {
        [styles.onlyIcon]: !title,
      })}
    >
      <Img className={styles.icon} src={icon} alt='' />
      {title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
    </div>
  );
};

export default SolutionsTag;
