import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Button, cn } from '@divlab/divanui';
import { IconBasket, IconCheckBasket } from '@divlab/divanui/icons';

import * as ModalWindows from '@Stores/ModalWindows';
import { useHasInCart } from '@Queries/Cart';
import { useMeta } from '@Queries/Meta';
import useNavigation from '@Navigation/useNavigation';
import ProductLike from '@Components/ProductLike';
import ProductTags from '@Components/ProductTags';
import GalleryProductPreviews from '@Components/GalleryProductPreviews';
import ExpiredPrice from '@Components/ExpiredPrice';
import ReadySolutions from '@Components/ReadySolutions';
import Characteristics from '@Components/Characteristics';
import LinearTag from '@Promo/elems/LinearTag';
import PreorderTab from '@Components/PreorderTab';
import SmartRender from '@Components/SmartRender';
import SEOBuyButton from '@Components/SEOBuyButton';
import ProductPreview from '@Components/ProductPreview';
import OnSaleSoonTab from '@Components/OnSaleSoonTab';
import AboutReceiptButton from '@Components/AboutReceiptButton';
import Link from '@Navigation/Link';
import useMedias from '@Hooks/useMedias';
import useRenderType from '@Hooks/useRenderType';
import { useRequest } from '@Contexts/Request';
import useTranslation from '@Queries/useTranslation';
import getProductName from '@Utils/getProductName';
import ProductSelect from './elements/ProductSelect';
import SEOMetaData from './elements/SEOMetaData';
import SolutionsTag from './elements/SolutionsTag';
import PenSvg from './pen.svg';
import Img from '@UI/Img';
import { useDeps } from '@Contexts/DI';
import CreditTabWrapper from '@Components/CreditTabWrapper';
import styles from './ProductCard.module.css';

import type { ProductData, ProductParameterGroupData, CustomizeData } from '@Types/Product';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface CharacteristicsData {
  type?: 'regular' | 'variants';
  items?: ProductParameterGroupData[];
}
export interface CardDetailsData {
  default: CharacteristicsData[];
  secondary: CharacteristicsData[];
}

interface CardCurrentDetailsData {
  default?: ProductParameterGroupData[];
  secondary?: ProductParameterGroupData[];
}

interface CardDetailsTempData extends CardDetailsData {
  current?: CardCurrentDetailsData;
}

export interface ProductCardProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product: ProductData;
  view?: 'mini';
  hasReadySolutions?: boolean;
  slug?: string;
  imageLazyLoading?: boolean;
  tagAnimation?: boolean;
  onClickProductLink?: (e: MouseEvent) => void;
}

const ProductCard: FC<ProductCardProps> = (props) => {
  const {
    className,
    product,
    view,
    hasReadySolutions,
    slug,
    imageLazyLoading,
    tagAnimation,
    onClickProductLink,
    ...restProps
  } = props;
  const [currentProduct, setCurrentProduct] = useState<ProductData>(product);
  const [, setHovered] = useState<boolean>(true);
  const { isSSR, isCSR } = useRenderType();
  const { isMobileM } = useMedias();
  const { t } = useTranslation();
  const [ref, inView] = useInView({ rootMargin: '200px 0px', triggerOnce: false });
  const isViewMini = view === 'mini';
  const [firstImage] = currentProduct.images;
  const isCreditAvailable = !!product.credit;
  const { country } = useRequest();
  const isUzb = country === 'UZB';
  const { openPage } = useNavigation();
  const { analytics } = useDeps();
  const meta = useMeta();
  const { region } = meta.data;
  const shopProduct = useMemo(
    () => ({
      isModular: false,
      shopProductId: currentProduct.id,
    }),
    [currentProduct.id],
  );
  const hasInCart = useHasInCart(shopProduct);

  const products = [product];
  const productIdWithColor = products.find((prod) => prod.readySolutions)?.id;
  const productIdWithCustomize = products.find((custom) => custom.customize)?.id;
  const isCustomizedProduct = productIdWithCustomize === product?.id;
  const isProductWithColors = productIdWithColor === product?.id;
  const isNotAvailable = isCSR && !product.isActive;
  const isComingSoon = currentProduct.status === 'ComingSoon';
  const isShowLinearTag =
    (isCreditAvailable || product.extraDiscount) && !isMobileM && !isComingSoon;

  const mainCredit = useMemo(() => {
    if (!isCreditAvailable) return null;
    if (!Array.isArray(currentProduct.credit)) return currentProduct.credit;

    const availableCredit = currentProduct.credit.find((credit) => credit.isMain);

    if (availableCredit) {
      return availableCredit;
    }
  }, [currentProduct.credit, isCreditAvailable]);

  const isYandexSplit = mainCredit?.type === 'yandexSplit';

  const parameters = useMemo<CardDetailsData>(() => {
    const isVariants = currentProduct.variants?.values.length >= 2;

    const group: CardDetailsTempData = currentProduct.parameterGroups.reduce(
      (acc: CardDetailsTempData, item: ProductParameterGroupData) => {
        const groupType = item.place;
        const isCurrentVariants =
          (isVariants || item.place === 'default') &&
          item.id === currentProduct.variants?.parameterGroupId;

        if (isCurrentVariants || !acc.current[groupType].length) {
          if (acc.current[groupType].length) {
            acc[groupType].push({ type: 'regular', items: [...acc.current[groupType]] });
          }
          acc.current[groupType] = [];
        }

        acc.current[groupType].push(item);

        if (isCurrentVariants) {
          acc[groupType].push({ type: 'variants', items: [...acc.current[groupType]] });
          acc.current[groupType] = [];
        }

        return acc;
      },
      { default: [], secondary: [], current: { default: [], secondary: [] } },
    );

    Object.keys(group.current).forEach((key) => {
      if (group.current[key].length) {
        group[key].push({ type: 'regular', items: [...group.current[key]] });
      }
    });

    return { default: group.default, secondary: group.secondary };
  }, [currentProduct]);

  const hasDefaultCharacteristics = parameters.default.length > 0;
  const hasSecondaryCharacteristics = parameters.secondary.length > 0;
  const isLastDefaultParametersRegular =
    hasDefaultCharacteristics &&
    parameters.default[parameters.default.length - 1]?.type === 'regular';

  const handleBuy = useCallback(
    (e: MouseEvent) => {
      if (onClickProductLink) onClickProductLink(e);

      if (hasInCart) {
        openPage({ url: `${region.url}/order/check` });
        return;
      }

      ModalWindows.open('Cart', {
        products: [shopProduct],
        analyticsLabel: 'Листинг',
      });
    },
    [hasInCart, onClickProductLink, openPage, region.url, shopProduct],
  );

  const handleChangeProduct = useCallback(
    (newProduct: ProductData) => {
      analytics.dispatchEvent('readySolution.previewOnHover', {
        prev: currentProduct,
        current: newProduct,
      });

      setCurrentProduct(newProduct);
    },
    [analytics, currentProduct],
  );

  const handleMouseLeave = useCallback(() => {
    setHovered(false);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setHovered(true);
  }, []);

  useEffect(() => {
    setCurrentProduct(product);
  }, [product]);

  const productCustomize = product?.customize;
  const customizeTranslate = `ui.customize.type-${productCustomize?.type}`;

  const handleClickSolutionTag = useCallback(
    (e: MouseEvent, type: string, customize?: CustomizeData) => {
      if (type === 'mobile') {
        const solutionTagModalData = {
          title: t(`${customizeTranslate}.modal-data.title`),
          description: t(`${customizeTranslate}.modal-data.description`),
          button: {
            text: t(`${customizeTranslate}.modal-data.button.text`),
            link: customize.link,
          },
          type: customize.type,
        };
        ModalWindows.open('SolutionTag', { tag: solutionTagModalData });
      }

      if (type === 'desktop' && onClickProductLink) onClickProductLink(e);

      analytics.dispatchEvent('labelTag.clickLabelTag', { product });
    },
    [analytics, product, customizeTranslate, onClickProductLink, t],
  );

  const productCustomizeClassNameCheck =
    (isCustomizedProduct && productCustomize?.type === 2) || productCustomize?.type === 3;
  const solutionTagType = productCustomize?.type !== 1 && productCustomize?.type;

  return (
    <div
      {...restProps}
      data-testid='product-card'
      className={cn(
        styles.productCard,
        {
          [styles.hasExtraDiscount]: product.extraDiscount,
          [styles.viewMini]: isViewMini,
          [styles.uzb]: isUzb,
        },
        className,
      )}
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.box} />

      <div className={styles.container}>
        <div className={styles.containerImage}>
          {inView ? (
            <GalleryProductPreviews
              images={currentProduct.images.map((item) => ({
                ...item,
                alt: getProductName(currentProduct),
              }))}
              link={currentProduct.link}
              orientation={currentProduct.orientation}
              imageLazyLoading={imageLazyLoading}
              onClick={onClickProductLink}
            />
          ) : (
            <ProductPreview
              image={{ ...firstImage, alt: getProductName(currentProduct) }}
              link={currentProduct.link}
              orientation={currentProduct.orientation}
              onClick={onClickProductLink}
            />
          )}
          <SmartRender countries={['RUS']} condition={!isMobileM && !!product.isPreorder}>
            <Link to={currentProduct.link} onClick={onClickProductLink}>
              <PreorderTab className={styles.preorderTab} />
            </Link>
          </SmartRender>
          {isComingSoon && !isMobileM && (
            <Link to={currentProduct.link} onClick={onClickProductLink}>
              <OnSaleSoonTab showIcon className={styles.saleSoonTab} />
            </Link>
          )}
          {isShowLinearTag && (
            <Link to={currentProduct.link} onClick={onClickProductLink}>
              {product.extraDiscount && !product.hasKitActivity && (
                <LinearTag
                  type='extra'
                  className={cn(styles.extraSaleTag, {
                    [styles.withCredit]: isCreditAvailable,
                  })}
                  animation={tagAnimation}
                />
              )}

              {product.hasKitActivity && (
                <LinearTag
                  type='kits'
                  className={cn(styles.extraSaleTag, {
                    [styles.withCredit]: isCreditAvailable,
                  })}
                  animation={tagAnimation}
                />
              )}
              {mainCredit && (
                <CreditTabWrapper
                  mainCredit={mainCredit}
                  className={cn(styles.creditTab, {
                    [styles.yandexCreditTab]: isYandexSplit,
                  })}
                />
              )}
            </Link>
          )}
          <div className={styles.actions}>
            <ProductLike className={styles.action} product={currentProduct} label='Листинг' />
          </div>
          {isCustomizedProduct && (
            <div className={styles.solutionTagMobile}>
              <Img
                onClick={(e) => handleClickSolutionTag(e, 'mobile', productCustomize)}
                className={styles.penIcon}
                src={PenSvg}
                alt=''
              />
            </div>
          )}
          {currentProduct.tags?.length > 0 && (
            <ProductTags className={styles.tags} tags={currentProduct.tags} />
          )}
        </div>

        <div className={styles.info}>
          <Link
            className={cn(
              styles.name,
              'ProductName',
              currentProduct.status === 'Disable' && 'ForCount',
              currentProduct.status === 'Active' && 'ActiveProduct',
            )}
            to={currentProduct.link}
            onClick={onClickProductLink}
          >
            <span itemProp='name'>{getProductName(currentProduct)}</span>
          </Link>

          <SEOMetaData currentProduct={currentProduct} />

          {isComingSoon && isMobileM && (
            <>
              <div className={styles.comingSoon}>
                <ExpiredPrice product={currentProduct} mini={isViewMini} />
                <AboutReceiptButton
                  isCircleView
                  product={product}
                  className={styles.comingSoonButton}
                />
              </div>
              <Link to={currentProduct.link} onClick={onClickProductLink}>
                <OnSaleSoonTab showIcon className={styles.saleSoonTab} />
              </Link>
            </>
          )}

          {isComingSoon && !isMobileM && (
            <div className={styles.wrapperPrice}>
              <ExpiredPrice product={currentProduct} mini={isViewMini} />
            </div>
          )}

          {/* Требование SEO чтобы от сервера всегда приходила цена,
              и только на клиенте изменялась на Нет в наличии */}
          {isNotAvailable && !isComingSoon && (
            <div className={styles.outOfStock}>{t('ui.not-available')}</div>
          )}
          {!isNotAvailable && !isComingSoon && (
            <>
              <div className={styles.wrapperPrice}>
                <ExpiredPrice product={currentProduct} mini={isViewMini} />
                {isMobileM && (
                  <>
                    {hasInCart ? (
                      <Button
                        view='circle'
                        theme='transparent'
                        className={styles.buyMobile}
                        onClick={handleBuy}
                        data-testid='icon-buy-button-added'
                      >
                        <IconCheckBasket className={styles.iconBasket} />
                      </Button>
                    ) : (
                      <Button
                        view='circle'
                        onClick={handleBuy}
                        className={styles.buyMobile}
                        data-testid='icon-buy-button'
                      >
                        <IconBasket className={styles.iconBasket} theme='light' />
                      </Button>
                    )}
                  </>
                )}
              </div>
              {isMobileM && product.isPreorder && (
                <Link to={currentProduct.link} onClick={onClickProductLink}>
                  <PreorderTab className={styles.preorderTab} />
                </Link>
              )}
              <SmartRender
                countries={['RUS', 'KAZ', 'BLR']}
                condition={isCreditAvailable && isMobileM}
              >
                <Link to={currentProduct.link} onClick={onClickProductLink}>
                  {product.hasKitActivity || product.extraDiscount ? (
                    <LinearTag
                      type={product.hasKitActivity ? 'kits' : 'extra'}
                      className={styles.extraSaleTag}
                      animation={tagAnimation}
                    />
                  ) : (
                    mainCredit && (
                      <CreditTabWrapper
                        mainCredit={mainCredit}
                        className={cn(styles.creditTab, {
                          [styles.yandexCreditTab]: isYandexSplit,
                        })}
                      />
                    )
                  )}
                </Link>
              </SmartRender>
            </>
          )}

          <div
            className={cn({
              [styles.readySolutionsContainer]: isProductWithColors && isCustomizedProduct,
              [styles.readySolutionsContainerSpace]: productCustomizeClassNameCheck,
            })}
          >
            {hasReadySolutions && isProductWithColors && (
              <ReadySolutions
                className={styles.fabricsWrapper}
                readySolutions={product.readySolutions}
                view={view}
                slug={slug}
                product={currentProduct}
                solutionTagType={solutionTagType}
                onChangeProduct={handleChangeProduct}
                onClickColor={onClickProductLink}
              />
            )}

            {isCustomizedProduct && (
              <div
                className={cn(styles.solutionTagDesktop, {
                  [styles.solutionTagDesktopWithoutColor]: !isProductWithColors,
                })}
              >
                <Link
                  to={productCustomize.link}
                  onClick={(e) => handleClickSolutionTag(e, 'desktop')}
                >
                  <SolutionsTag
                    tag={{
                      title: productCustomize.type === 1 ? '' : t(`${customizeTranslate}.title`),
                      icon: PenSvg,
                    }}
                  />
                </Link>
              </div>
            )}
          </div>

          {hasDefaultCharacteristics && (
            <div className={styles.characteristicsWrapper}>
              {parameters.default.map((parameter) => {
                return parameter.type === 'variants' ? (
                  <ProductSelect
                    className={styles.sizes}
                    product={currentProduct}
                    onClickItem={onClickProductLink}
                  />
                ) : (
                  <Characteristics
                    className={styles.parameterGroups}
                    groups={parameter.items}
                    product={currentProduct}
                  />
                );
              })}
            </div>
          )}
        </div>

        {/* Обязательно включаем в SSR, но на клиенте рендерим только во вьюпорте, чтобы улучшить производительность */}
        {(isSSR || (inView && !isMobileM)) && (
          <div data-testid='product-card-2' className={styles.additionalInfo}>
            {hasSecondaryCharacteristics && (
              <div className={styles.characteristicsWrapper}>
                {parameters.secondary.map((parameter, index) => {
                  return parameter.type === 'variants' ? (
                    <ProductSelect
                      key={index}
                      className={styles.sizes}
                      product={currentProduct}
                      onClickItem={onClickProductLink}
                    />
                  ) : (
                    <Characteristics
                      key={index}
                      className={cn(styles.parameterGroups, {
                        [styles.firstCharacteristics]:
                          index === 0 && isLastDefaultParametersRegular,
                      })}
                      groups={parameter.items}
                      product={currentProduct}
                    />
                  );
                })}
              </div>
            )}
            {isComingSoon ? (
              <AboutReceiptButton
                className={styles.aboutReceipt}
                cnText={styles.aboutReceiptText}
                product={currentProduct}
              />
            ) : (
              <>
                <SEOBuyButton
                  data-testid='buy-button'
                  className={styles.buy}
                  product={currentProduct}
                  onClick={handleBuy}
                />
                {isCSR && (
                  <div className={styles.moreWrapper}>
                    <Link
                      className={styles.more}
                      to={currentProduct.link}
                      view='secondary'
                      underlined
                      onClick={onClickProductLink}
                    >
                      {t('ui.more-about-product')}
                    </Link>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ProductCard);
