import { memo } from 'react';
import { cn } from '@divlab/divanui';

import styles from './ServicePageTitle.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface ServicePageTitleProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  cnTitle?: string;
  title: string;
  view?: 'bordered';
  titleAlign?: 'leftOnMobile';
}

const ServicePageTitle: FC<ServicePageTitleProps> = (props) => {
  const { className, cnTitle, title, view, titleAlign, ...restProps } = props;

  return (
    <div {...restProps} className={className}>
      <h1
        itemProp='name'
        className={cn(
          styles.title,
          {
            [styles.bordered]: view === 'bordered',
            [styles.leftAlign]: titleAlign === 'leftOnMobile',
          },
          cnTitle,
        )}
      >
        {title}
      </h1>
    </div>
  );
};

export default memo(ServicePageTitle);
