import { memo } from 'react';
import { cn } from '@divlab/divanui';

import styles from './PreorderTab.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface PreorderTabProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const PreorderTab: FC<PreorderTabProps> = (props) => {
  const { className, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.tab, className)}>
      Предзаказ
    </div>
  );
};

export default memo(PreorderTab);
