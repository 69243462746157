import { memo, useCallback, useMemo } from 'react';
import { cn } from '@divlab/divanui';

import useNavigation from '@Navigation/useNavigation';
import * as ModalWindows from '@Stores/ModalWindows';
import Fabrics from '@Components/Fabrics';
import useMedias from '@Hooks/useMedias';
import { useDeps } from '@Contexts/DI';
import styles from './ReadySolutions.module.css';

import type { ColorData, ProductData, ReadySolutionsData } from '@Types/Product';
import type { FC, HTMLAttributes, MouseEvent } from 'react';

export interface ReadySolutionsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  readySolutions?: ReadySolutionsData;
  view?: 'mini';
  product: ProductData;
  imageLazyLoading?: boolean;
  solutionTagType?: number;
  onChangeProduct?: (id: number) => void;
  onClickColor?: (e: MouseEvent, clickedFabric: ColorData) => void;
}

const ReadySolutions: FC<ReadySolutionsProps> = (props) => {
  const {
    className,
    readySolutions,
    product,
    solutionTagType,
    imageLazyLoading,
    onChangeProduct,
    onClickColor,
    ...restProps
  } = props;
  const { isMobile } = useMedias();

  const { analytics } = useDeps();
  const { navigate } = useNavigation();
  const counterVisibleFabricsColor = !isMobile ? 7 : 5;
  const visibleFabricsCount = solutionTagType ? 3 : counterVisibleFabricsColor;

  const colors = useMemo(() => {
    return readySolutions?.colors || [];
  }, [readySolutions]);

  const defaultSelectedFabric = useMemo(() => {
    return colors.find((color) => color.shopProductId === product.id);
  }, [product.id, colors]);

  const fabricColorSlice = useMemo(() => {
    return colors.slice(0, visibleFabricsCount);
  }, [colors, visibleFabricsCount]);

  const counterFabricColors = (readySolutions?.configuratorCount || 0) - fabricColorSlice.length;

  const handleClickConstructor = useCallback(() => {
    if (isMobile || product.isConfiguratorOnPage) return;

    analytics.dispatchEvent('constructor.clickToOpen', {
      source: 'Превью карточки товара',
    });

    ModalWindows.open('Constructor', {
      productId: product.id,
      source: 'Превью карточки товара',
    });
  }, [isMobile, product.id, analytics, product.isConfiguratorOnPage]);

  const handleClickColor = useCallback(
    (e: MouseEvent<HTMLDivElement>, clickedFabric: ColorData) => {
      analytics.dispatchEvent('readySolution.select', {
        category: 'ColorCategory',
        name: product.name,
      });

      if (isMobile && fabricColorSlice.length > 1) {
        if (onClickColor) onClickColor(e, fabricColorSlice[0]);
        navigate(fabricColorSlice[0].link);
      } else if (onClickColor) {
        onClickColor(e, clickedFabric);
      }
    },
    [product.name, onClickColor, analytics, isMobile, fabricColorSlice, navigate],
  );

  const handleChangeProduct = useCallback(
    (newProductId: number) => {
      if (isMobile) return;

      if (onChangeProduct) onChangeProduct(newProductId);
    },
    [isMobile, onChangeProduct],
  );

  return (
    <div {...restProps} className={className}>
      {colors.length > 0 ? (
        <div className={styles.fabricsWrapper}>
          <Fabrics
            size={isMobile ? 'xs' : 's'}
            fabrics={fabricColorSlice}
            defaultSelectedFabric={defaultSelectedFabric}
            imageLazyLoading={imageLazyLoading}
            onChangeProduct={handleChangeProduct}
            onClickColor={handleClickColor}
            isLink={!isMobile}
          />
          {counterFabricColors > 0 && (
            <div className={styles.fabricsMore} onClick={handleClickConstructor}>
              {`+${counterFabricColors}`}
            </div>
          )}
        </div>
      ) : (
        <div {...restProps} className={cn(styles.fabricsPlaceholder, className)} />
      )}
    </div>
  );
};

export default memo(ReadySolutions);
