import { memo } from 'react';
import { Discount, cn, Price } from '@divlab/divanui';

import * as Meta from '@Queries/Meta';
import useMedias from '@Hooks/useMedias';
import { useRequest } from '@Contexts/Request';
import styles from './ExpiredPrice.module.css';

import type { ProductData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface ExpiredPriceProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  product: ProductData;
  mini?: boolean;
}

const ExpiredPrice: FC<ExpiredPriceProps> = (props) => {
  const { className, product, mini, ...restProps } = props;
  const { price } = product;
  const meta = Meta.useMeta();
  const { isMobileM } = useMedias();
  const { country } = useRequest();
  const hasExpired = price.expired > 0;
  const hasDiscount = price.discount > 0;
  const isLongPrice = ['KZT', 'UZS'].includes(meta.data?.currency);
  const isUZB = country === 'UZB';

  return (
    <div
      {...restProps}
      className={cn(
        styles.container,
        {
          [styles.promo]: product.promo,
          [styles.mini]: mini,
          [styles.longPrice]: isLongPrice,
          [styles.uzb]: isUZB,
        },
        className,
      )}
    >
      <Price className={styles.actualPrice} price={price.actual} />
      {hasExpired && (
        <Price
          expired
          className={styles.expiredPrice}
          price={price.expired}
          withoutCurrency={isUZB && !isMobileM}
        />
      )}
      {!hasExpired && mini && isLongPrice && (
        <span className={cn(styles.expiredPrice, styles.expiredSpace)}></span>
      )}
      {hasDiscount && (
        <Discount className={styles.discount} value={price.discount} view='grayStroke' />
      )}
    </div>
  );
};

export default memo(ExpiredPrice);
