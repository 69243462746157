import { memo } from 'react';
import { cn, Gallery } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import Img from '@UI/Img';
import Link from '@Navigation/Link';
import ImageAreas from '@Components/ImageAreas';
import ProductImage from '@Components/ProductImage';
import styles from './GalleryProductPreviews.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { Orientation } from '@Types/Base';
import type { ProductImageData } from '@Types/Product';

export interface GalleryProductPreviewsProps
  extends HTMLAttributes<HTMLDivElement & HTMLAnchorElement> {
  className?: string;
  images?: ProductImageData[];
  link: string;
  modalView?: boolean;
  orientation?: Orientation;
  imageLazyLoading?: boolean;
  cnImage?: string;
}

const GalleryProductPreviews: FC<GalleryProductPreviewsProps> = (props) => {
  const {
    className,
    images = [],
    link,
    modalView,
    orientation = 'landscape',
    imageLazyLoading,
    cnImage,
    onClick,
    ...restProps
  } = props;
  const { isOnlyDesktop } = useMedias();
  const [firstImage] = images;
  const hasGallery = images.length > 1;

  return (
    <div
      {...restProps}
      className={cn(
        styles.previews,
        {
          [styles.landscape]: orientation === 'landscape',
          [styles.portrait]: orientation === 'portrait',
        },
        className,
      )}
    >
      <Link
        to={link}
        className={styles.link}
        target={modalView ? '_blank' : '_self'}
        onClick={onClick}
      >
        <div className={styles.content}>
          {hasGallery ? (
            <>
              {isOnlyDesktop ? (
                <ImageAreas cnImage={cnImage} images={images} imageLazyLoading={imageLazyLoading} />
              ) : (
                <Gallery className={cn(styles.gallery, className)}>
                  {images.map((image, index) => (
                    <div className={styles.item} key={image.src}>
                      <Img
                        className={styles.image}
                        cnImage={cnImage}
                        src={image.src}
                        alt={image.alt}
                        loading={index !== 0 || imageLazyLoading ? 'lazy' : 'eager'}
                        itemProp='image'
                      />
                    </div>
                  ))}
                </Gallery>
              )}
            </>
          ) : (
            <ProductImage
              className={styles.image}
              cnImage={cnImage}
              src={firstImage?.src}
              alt={firstImage.alt}
              loading={imageLazyLoading ? 'lazy' : 'eager'}
              itemProp='image'
            />
          )}
        </div>
      </Link>
    </div>
  );
};

export default memo(GalleryProductPreviews);
